<template>
  <KTCard :page="page">
    <template #body>
      <KTTable
        :fields="despesa_operacional_columns"
        :items="despesa_operacional.data"
        :primaryKey="despesa_operacional.primaryKey"
        :sortBy="despesa_operacional.sortBy"
        :paginated="false"
        :searchable="false"
      >
        <template #custom-foot>
          <tr
            class="bg-primary text-white"
            v-if="despesa_operacional.foot_data[0]"
          >
            <td>Total</td>
            <td class="text-center">
              {{ despesa_operacional.foot_data[0].simbolo_moeda }}
            </td>
            <template v-for="(ano, index) in anos">
              <td :key="'td-anos-' + index" class="text-right">
                {{
                  formatCurrency(
                    despesa_operacional.foot_data[0]["valor_total_ano_" + ano]
                  )
                }}
              </td>
            </template>
          </tr>
        </template>
      </KTTable>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipResumoDespesaOperacionalList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-thumbs-down",
        title: "Resumo Despesas Operacionais",
        description: `Aqui não é necessário preencher nenhuma informação,
          mas aproveite para conferir se os valores estão consistentes.
          Caso seja necessário corrigir algum lançamento, retorne para
          Despesas Operacionais.`
      },
      despesa_operacional: {
        data: [],
        foot_data: [],
        primaryKey: "tipo_despesa_operacional",
        sortBy: ""
      },
      anos: 0,
      tipo_plano_investimento: null
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/resumo_despesa_operacional`;
    },
    manual() {
      return {
        session: "resumo_despesa_operacional",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    despesa_operacional_columns() {
      let columns = [
        {
          key: "descricao_tipo_despesa_operacional",
          label: "Discriminação",
          sortable: false
        },
        {
          key: "simbolo_moeda",
          label: "Moeda",
          sortable: false,
          tdClass: "text-center"
        }
      ];

      for (let ano = 1; ano <= this.anos; ano++) {
        columns.push({
          key: "valor_total_ano_" + ano,
          label: "Valor Ano " + ano,
          sortable: false,
          tdClass: "text-right",
          formatter: "currency"
        });
      }

      return columns;
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.despesa_operacional.data = res[R_GETLIST].despesa_operacional;
        this.despesa_operacional.foot_data =
          res[R_GETLIST].despesa_operacional_total;
        this.anos = res[R_GETLIST].anos;
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
